import sal from 'sal.js'

sal({
	// once: false,
});

document.querySelectorAll('.g-nav-open').forEach(gnav => {
	gnav.addEventListener('click', function (e) {
		this.classList.toggle('active');
		document.getElementById('g-nav').classList.toggle('panelactive');
		document.body.classList.toggle('no-scrol');
	}.bind(gnav))
});

document.querySelectorAll('#g-nav a').forEach(gnavLink => {
	gnavLink.addEventListener('click', () => {
		document.querySelectorAll('.g-nav-open').forEach((gnav) => {
			gnav.classList.remove('active');
		})
		document.getElementById('g-nav').classList.remove('panelactive');
		document.body.classList.remove('no-scrol');
	})
});

document.querySelectorAll('.js_h').forEach(jsh => {
	['mouseenter', 'touchstart'].forEach(event => {
		jsh.addEventListener(event, () => {
			jsh.classList.add('hover');
		})
	});
	['mouseleave', 'touchend'].forEach(event => {
		jsh.addEventListener(event, () => {
			jsh.classList.remove('hover');
		})
	});
});

const yearSelect = document.getElementById('selector-archive-fiscal-year');
if (yearSelect != null) {
	yearSelect.addEventListener('change', function() {
		window.location.href = this.value;
	}, false);
}
